import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Accueil" description="La compagnie Ailes de Cire propose des ateliers chorégraphiques, ainsi que des cours de danse, ouverts à tout types de publics et de corps. Elle regroupe un réseau d’artistes au parcours atypique et pluridisciplinaire." />
    <section id="home-banner" title="Carte Blanche, Toulouse place Saint Sernin, 2021, Photo d'Adam Hooke">

    </section>
    <section className="main container">
      <h1 style={{visibility: `hidden`}}>Accueil</h1>
    </section>
  </Layout>
)

export default IndexPage
